// Homepage Gallery

document.addEventListener("DOMContentLoaded", function() {

	const DEBUG = false;
	const slides = document.querySelectorAll("div.homepage-gallery img");
	const timer = 5000;
	const total = slides.length;

	let autoplay = null;
	let current = 0;
	let previous = 0;

	function advance() {
		if (DEBUG) console.log("advance()");
		if (DEBUG) console.log(current + ":" + total);

		previous = current;
		if (current < total-1){
			current++;
		} else {
			current = 0;
		}

		slides[current].classList.add("active", "ambient");
		slides[previous].classList.remove("active");

		setTimeout(function(){
			slides[previous].classList.remove("ambient");
		}, timer/2);
		
	}

	function start() {
		if (DEBUG) console.log("start()");
		slides[current].classList.add("ambient");
		autoplay = setInterval( advance, timer );
	}

	function stop() {
		if (DEBUG) console.log("stop()");
		clearInterval(autoplay);
	}

	// Run
	start();

});